const initialSpace = {
  modules: {},
  theme: {}
};

export const getModule = (data = initialSpace, module) => {
  return data.modules[module];
};

export const hasModule = (data = initialSpace, module) => {
  return getModule(data, module)?.enabled;
};

export const getAllModule = (data = initialSpace) => {
  return Object.keys(data.modules)
    .filter((key) => data.modules[key].enabled)
    .map((key) => data.modules[key]);
};

export const getTheme = ({ theme } = initialSpace) => {
  return theme;
};

const defaultCbk = () => {};
export const buildCallbacks = (space) => {
  if (!space) {
    return {
      getModule: defaultCbk,
      hasModule: defaultCbk,
      getAllModule: defaultCbk,
      getTheme: defaultCbk
    };
  }
  return {
    getModule: (module) => getModule(space, module),
    hasModule: (module) => hasModule(space, module),
    getAllModule: () => getAllModule(space),
    getTheme: () => getTheme(space)
  };
};
