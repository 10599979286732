import {findProvinceByAcronym} from './Provinces'

export const PRI = [
  {
    'code': '2101',
    'name': 'Sandino'
  },
  {
    'code': '2102',
    'name': 'Mantua'
  },
  {
    'code': '2103',
    'name': 'Minas de Matahambre'
  },
  {
    'code': '2104',
    'name': 'Viñales'
  },
  {
    'code': '2105',
    'name': 'La Palma'
  },
  {
    'code': '2106',
    'name': 'Los Palacios'
  },
  {
    'code': '2107',
    'name': 'Consolación del sur'
  },
  {
    'code': '2108',
    'name': 'Pinar del Río'
  },
  {
    'code': '2109',
    'name': 'San Luis'
  },
  {
    'code': '2110',
    'name': 'San Juan y Martínez'
  },
  {
    'code': '2111',
    'name': 'Guane'
  }
];

export const ART = [
  {
    'code': '2201',
    'name': 'Bahía Honda'
  },
  {
    'code': '2202',
    'name': 'Mariel'
  },
  {
    'code': '2203',
    'name': 'Guanajay'
  },
  {
    'code': '2204',
    'name': 'Caimito'
  },
  {
    'code': '2205',
    'name': 'Bauta'
  },
  {
    'code': '2206',
    'name': 'San Antonio de los Baños'
  },
  {
    'code': '2207',
    'name': 'Güira de Melena'
  },
  {
    'code': '2208',
    'name': 'Alquízar'
  },
  {
    'code': '2209',
    'name': 'Artemisa'
  },
  {
    'code': '2210',
    'name': 'Candelaria'
  },
  {
    'code': '2211',
    'name': 'San Cristóbal'
  }
];

export const HAB = [
  {
    'name': 'Habana del Este',
    'code': '2306'
  },
  {
    'name': 'Centro Habana',
    'code': '2303'
  },
  {
    'name': 'Regla',
    'code': '2305'
  },
  {
    'name': 'Plaza de la Revolución',
    'code': '2302'
  },
  {
    'name': 'La Habana Vieja',
    'code': '2304'
  },
  {
    'name': 'Cerro',
    'code': '2310'
  },
  {
    'name': 'Diez de Octubre',
    'code': '2309'
  },
  {
    'name': 'Guanabacoa',
    'code': '2307'
  },
  {
    'name': 'San Miguel del Padrón',
    'code': '2308'
  },
  {
    'name': 'Playa',
    'code': '2301'
  },
  {
    'name': 'Arroyo Naranjo',
    'code': '2314'
  },
  {
    'name': 'Boyeros',
    'code': '2313'
  },
  {
    'name': 'Marianao',
    'code': '2311'
  },
  {
    'name': 'Cotorro',
    'code': '2315'
  },
  {
    'name': 'La Lisa',
    'code': '2312'
  }
];

export const MAY = [
  {
    'name': 'Santa Cruz del Norte',
    'code': '2404'
  },
  {
    'name': 'San José de las Lajas',
    'code': '2402'
  },
  {
    'name': 'Jaruco',
    'code': '2403'
  },
  {
    'name': 'Madruga',
    'code': '2405'
  },
  {
    'name': 'Bejucal',
    'code': '2401'
  },
  {
    'name': 'Quivicán',
    'code': '2411'
  },
  {
    'name': 'Güines',
    'code': '2408'
  },
  {
    'name': 'San Nicolás de Bari',
    'code': '2407'
  },
  {
    'name': 'Nueva Paz',
    'code': '2406'
  },
  {
    'name': 'Batabanó',
    'code': '2410'
  },
  {
    'name': 'Melena del Sur',
    'code': '2409'
  }
];

export const MTZ = [
  {
    'name': 'Martí',
    'code': '2503'
  },
  {
    'name': 'Cárdenas',
    'code': '2502'
  },
  {
    'name': 'Matanzas',
    'code': '2501'
  },
  {
    'name': 'Limonar',
    'code': '2508'
  },
  {
    'name': 'Unión de Reyes',
    'code': '2509'
  },
  {
    'name': 'Perico',
    'code': '2505'
  },
  {
    'name': 'Jovellanos',
    'code': '2506'
  },
  {
    'name': 'Pedro Betancourt',
    'code': '2507'
  },
  {
    'name': 'Los Arabos',
    'code': '2513'
  },
  {
    'name': 'Colón',
    'code': '2504'
  },
  {
    'name': 'Jagüey Grande',
    'code': '2511'
  },
  {
    'name': 'Calimete',
    'code': '2512'
  },
  {
    'name': 'Ciénaga de Zapata',
    'code': '2510'
  }
];

export const VCL = [
  {
    'name': 'Sagua La Grande',
    'code': '2603'
  },
  {
    'name': 'Corralillo',
    'code': '2601'
  },
  {
    'name': 'Quemado de Güines',
    'code': '2602'
  },
  {
    'name': 'Santo Domingo',
    'code': '2611'
  },
  {
    'name': 'Cifuentes',
    'code': '2610'
  },
  {
    'name': 'Ranchuelo',
    'code': '2612'
  },
  {
    'name': 'Santa Clara',
    'code': '2609'
  },
  {
    'name': 'Manicaragua',
    'code': '2613'
  },
  {
    'name': 'Encrucijada',
    'code': '2604'
  },
  {
    'name': 'Camajuaní',
    'code': '2605'
  },
  {
    'name': 'Caibarién',
    'code': '2606'
  },
  {
    'name': 'Remedios',
    'code': '2607'
  },
  {
    'name': 'Placetas',
    'code': '2608'
  }
];

export const CFG = [
  {
    'name': 'Rodas',
    'code': '2702'
  },
  {
    'name': 'Lajas',
    'code': '2704'
  },
  {
    'name': 'Aguada de Pasajeros',
    'code': '2701'
  },
  {
    'name': 'Palmira',
    'code': '2703'
  },
  {
    'name': 'Cruces',
    'code': '2705'
  },
  {
    'name': 'Cumanayagua',
    'code': '2706'
  },
  {
    'name': 'Cienfuegos',
    'code': '2707'
  },
  {
    'name': 'Abreus',
    'code': '2708'
  }
];

export const SSP =  [
  {
    'name': 'Trinidad',
    'code': '2806'
  },
  {
    'name': 'Yaguajay',
    'code': '2801'
  },
  {
    'name': 'Cabaiguán',
    'code': '2804'
  },
  {
    'name': 'Fomento',
    'code': '2805'
  },
  {
    'name': 'Taguasco',
    'code': '2803'
  },
  {
    'name': 'Jatibonico',
    'code': '2802'
  },
  {
    'name': 'Sancti Spíritus',
    'code': '2807'
  },
  {
    'name': 'La Sierpe',
    'code': '2808'
  }
];

export const CAV = [
  {
    'name': 'Chambas',
    'code': '2901'
  },
  {
    'name': 'Morón',
    'code': '2902'
  },
  {
    'name': 'Florencia',
    'code': '2906'
  },
  {
    'name': 'Ciro Redondo',
    'code': '2905'
  },
  {
    'name': 'Bolivia',
    'code': '2903'
  },
  {
    'name': 'Primero de Enero',
    'code': '2904'
  },
  {
    'name': 'Majagua',
    'code': '2907'
  },
  {
    'name': 'Ciego de Ávila',
    'code': '2908'
  },
  {
    'name': 'Baraguá',
    'code': '2910'
  },
  {
    'name': 'Venezuela',
    'code': '2909'
  }
];

export const CAM = [
  {
    'name': 'Nuevitas',
    'code': '3005'
  },
  {
    'name': 'Esmeralda',
    'code': '3002'
  },
  {
    'name': 'Sierra de Cubitas',
    'code': '3003'
  },
  {
    'name': 'Minas',
    'code': '3004'
  },
  {
    'name': 'Carlos Manuel de Céspedes',
    'code': '3001'
  },
  {
    'name': 'Florida',
    'code': '3009'
  },
  {
    'name': 'Camagüey',
    'code': '3008'
  },
  {
    'name': 'Guáimaro',
    'code': '3006'
  },
  {
    'name': 'Sibanicú',
    'code': '3007'
  },
  {
    'name': 'Vertientes',
    'code': '3010'
  },
  {
    'name': 'Jimaguayú',
    'code': '3011'
  },
  {
    'name': 'Santa Cruz del Sur',
    'code': '3013'
  },
  {
    'name': 'Najasa',
    'code': '3012'
  }
];

export const LTU = [
  {
    'name': 'Manatí',
    'code': '3101'
  },
  {
    'name': 'Jesús Menéndez',
    'code': '3103'
  },
  {
    'name': 'Puerto Padre',
    'code': '3102'
  },
  {
    'name': 'Las Tunas',
    'code': '3105'
  },
  {
    'name': 'Jobabo',
    'code': '3106'
  },
  {
    'name': 'Colombia',
    'code': '3107'
  },
  {
    'name': 'Amancio',
    'code': '3108'
  },
  {
    'name': 'Majibacoa',
    'code': '3104'
  }
];

export const HOL = [
  {
    'name': 'Banes',
    'code': '3203'
  },
  {
    'name': 'Gibara',
    'code': '3201'
  },
  {
    'name': 'Rafael Freyre',
    'code': '3202'
  },
  {
    'name': 'Calixto García',
    'code': '3207'
  },
  {
    'name': 'Holguín',
    'code': '3206'
  },
  {
    'name': 'Báguanos',
    'code': '3205'
  },
  {
    'name': 'Antilla',
    'code': '3204'
  },
  {
    'name': 'Mayarí',
    'code': '3211'
  },
  {
    'name': 'Cacocum',
    'code': '3208'
  },
  {
    'name': 'Moa',
    'code': '3214'
  },
  {
    'name': 'Frank País',
    'code': '3212'
  },
  {
    'name': 'Cueto',
    'code': '3210'
  },
  {
    'name': 'Urbano Noris',
    'code': '3209'
  },
  {
    'name': 'Sagua de Tánamo',
    'code': '3213'
  }
];

export const GRM = [
  {
    'name': 'Río Cauto',
    'code': '3301'
  },
  {
    'name': 'Cauto Cristo',
    'code': '3302'
  },
  {
    'name': 'Bayamo',
    'code': '3304'
  },
  {
    'name': 'Jiguaní',
    'code': '3303'
  },
  {
    'name': 'Yara',
    'code': '3305'
  },
  {
    'name': 'Manzanillo',
    'code': '3306'
  },
  {
    'name': 'Guisa',
    'code': '3313'
  },
  {
    'name': 'Buey Arriba',
    'code': '3312'
  },
  {
    'name': 'Bartolomé Masó',
    'code': '3311'
  },
  {
    'name': 'Media Luna',
    'code': '3308'
  },
  {
    'name': 'Niquero',
    'code': '3309'
  },
  {
    'name': 'Pilón',
    'code': '3310'
  },
  {
    'name': 'Campechuela',
    'code': '3307'
  }
];

export const SCU = [
  {
    'name': 'Segundo Frente',
    'code': '3404'
  },
  {
    'name': 'Mella',
    'code': '3402'
  },
  {
    'name': 'Contramaestre',
    'code': '3401'
  },
  {
    'name': 'San Luis',
    'code': '3403'
  },
  {
    'name': 'Palma Soriano',
    'code': '3407'
  },
  {
    'name': 'Songo La Maya',
    'code': '3405'
  },
  {
    'name': 'Tercer Frente',
    'code': '3408'
  },
  {
    'name': 'Santiago de Cuba',
    'code': '3406'
  },
  {
    'name': 'Guamá',
    'code': '3409'
  }
];

export const GTM = [
  {
    'name': 'Guantánamo',
    'code': '3509'
  },
  {
    'name': 'Yateras',
    'code': '3503'
  },
  {
    'name': 'El Salvador',
    'code': '3501'
  },
  {
    'name': 'Manuel Tames',
    'code': '3502'
  },
  {
    'name': 'Niceto Pérez',
    'code': '3510'
  },
  {
    'name': 'Caimanera',
    'code': '3508'
  },
  {
    'name': 'San Antonio del Sur',
    'code': '3507'
  },
  {
    'name': 'Baracoa',
    'code': '3504'
  },
  {
    'name': 'Imías',
    'code': '3506'
  },
  {
    'name': 'Maisí',
    'code': '3505'
  }
];

export const ISJ = [
  {
    'code': '4001',
    'name': 'Isla de la Juventud'
  }
];

export const MUNICIPALITIES = {
  PRI,
  ART,
  HAB,
  MAY,
  MTZ,
  VCL,
  CFG,
  SSP,
  CAV,
  CAM,
  LTU,
  HOL,
  GRM,
  SCU,
  GTM,
  ISJ
};

export const findMunicipality = (predicate) => Object.values(MUNICIPALITIES).flatMap(province => Object.values(province)).find(predicate);
export const findMunicipalityByCode = (code) => findMunicipality(municipality => code == municipality.code);
export const findMunicipalityByName = (name) => findMunicipality(municipality => name?.toLowerCase() === municipality.name?.toLowerCase());
export const findProvinceAcronymByMunicipalityCode = (code) => {
  const entry = Object.entries(MUNICIPALITIES).find(entry => 1 === Object.values(entry[1]).filter(municipality => code == municipality.code).length);
  return entry ? entry[0] : null;
};
export const findProvinceByMunicipalityCode = (code) => {
  const key = findProvinceAcronymByMunicipalityCode(code);
  return findProvinceByAcronym(key);
};
