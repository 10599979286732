import map from 'lodash/map'

export const PROVINCES = {
  PRI: {
    code: '21',
    acronym: 'PRI',
    name: 'Pinar del Río',
  },
  ART: {
    code: '22',
    acronym: 'ART',
    name: 'Artemisa',
  },
  HAB: {
    code: '23',
    acronym: 'HAB',
    name: 'La Habana',
  },
  MAY: {
    code: '24',
    acronym: 'MAY',
    name: 'Mayabeque',
  },
  MTZ: {
    code: '25',
    acronym: 'MTZ',
    name: 'Matanzas',
  },
  VCL: {
    code: '26',
    acronym: 'VCL',
    name: 'Villa Clara',
  },
  CFG: {
    code: '27',
    acronym: 'CFG',
    name: 'Cienfuegos',
  },
  SSP: {
    code: '28',
    acronym: 'SSP',
    name: 'Sancti Spíritus',
  },
  CAV: {
    code: '29',
    acronym: 'CAV',
    name: 'Ciego de Ávila',
  },
  CAM: {
    code: '30',
    acronym: 'CAM',
    name: 'Camagüey',
  },
  LTU: {
    code: '31',
    acronym: 'LTU',
    name: 'Las Tunas',
  },
  HOL: {
    code: '32',
    acronym: 'HOL',
    name: 'Holguín',
  },
  GRM: {
    code: '33',
    acronym: 'GRM',
    name: 'Granma',
  },
  SCU: {
    code: '34',
    acronym: 'SCU',
    name: 'Santiago de Cuba',
  },
  GTM: {
    code: '35',
    acronym: 'GTM',
    name: 'Guantánamo',
  },
  ISJ: {
    code: '36',
    anotherCodes: ['40'],
    acronym: 'ISJ',
    name: 'Isla de la Juventud',
  }
}

export const PROVINCES_ENUM = Object.keys(PROVINCES)
export const PROVINCES_FILTER = map(PROVINCES, ({ name }, key) => ({ text: name, value: key }))
export const PROVINCES_SELECT = map(PROVINCES, ({ name }, key) => ({ label: name, value: key }))

export const findProvince = (predicate) => Object.values(PROVINCES).find(predicate)
export const findProvinceByAcronym = (acronym) => findProvince(province => acronym === province.acronym)
export const findProvinceByCode = (code) => findProvince(province => code == province.code || (province.anotherCodes && province.anotherCodes.some((e) => e == code)))
export const findProvinceByName = (name) => findProvince(province => name?.toLowerCase() === province.name?.toLowerCase())
