import React, {memo} from 'react';
import PropTypes from 'prop-types';
import BusinessInfo from '../BusinessInfo';
import WorkTime from '../WorkTime';
import classNames from 'classnames';

const info = [
  {
    label: <div>Horario</div>,
    field: 'businessHours',
    widget: WorkTime
  }
];

const BusinessHours = ({className, actions, business}) => {

  return (
    <BusinessInfo
      className={classNames(className)}
      gray
      actions={actions}
      info={info}
      business={business}
    />
  );

};

export default memo(BusinessHours);

BusinessHours.propTypes = {
  className: PropTypes.string,
  actions: PropTypes.any,
  business: PropTypes.object
};
