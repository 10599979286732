const source = process.env.REACT_APP_FILE_STORAGE || process.env.NEXT_PUBLIC_FILE_STORAGE;
const prefixSource = process.env.REACT_APP_PREFIX_FILE_STORAGE || process.env.NEXT_PUBLIC_PREFIX_FILE_STORAGE || '';

const sourcePath = `${source}${prefixSource}`;

export const getFromSource = image => {
  if (!image) return '';
  const isSocialOrCDNAvatar = (image && image.startsWith('http'));
  return isSocialOrCDNAvatar ? image : `${sourcePath}/${image}`;
};


export const LOGO_PLACEHOLDER = process.env.REACT_APP_LOGO_PLACEHOLDER || process.env.NEXT_LOGO_PLACEHOLDER || '/assets/images/thumb-placeholder.png';
export const SERVICE_PLACEHOLDER = process.env.REACT_APP_SERVICE_PLACEHOLDER || process.env.NEXT_SERVICE_PLACEHOLDER || '/assets/images/services_placeholder.png';
