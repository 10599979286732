import {RiRestaurantLine, RiToolsLine, RiPencilRulerLine, RiWaterFlashLine} from 'react-icons/ri';
import {AiOutlineCar} from 'react-icons/ai';
import {VscRadioTower} from 'react-icons/vsc';
import {IoIosPeople} from 'react-icons/io';
import {MdBusiness, MdFaceRetouchingNatural, MdPool} from 'react-icons/md';
import {BiHotel} from 'react-icons/bi';
import {GiFarmer} from 'react-icons/gi'
import {GrUserWorker} from 'react-icons/gr';
import {FaRecycle, FaRegHandshake, FaWarehouse} from 'react-icons/fa';
import {GiSewingMachine} from 'react-icons/gi';
import {IoListCircleOutline} from 'react-icons/io5'
import React from 'react';

export const businessCategories = [
  {
    name: 'Servicios técnicos',
    icon: <RiToolsLine />,
    link: '/directorio-de-empresas?categories=Servicios%20técnicos'
  },
  {
    name: 'Manufactura',
    icon: <GiSewingMachine />,
    link: '/directorio-de-empresas?categories=Manufactura'
  },
  {
    name: 'Comercio y gastronomía',
    icon: <RiRestaurantLine />,
    link: '/directorio-de-empresas?categories=Comercio%20y%20gastronomía'
  },
  {
    name: 'Transporte',
    icon: <AiOutlineCar />,
    link: '/directorio-de-empresas?categories=Transporte'
  },
  {
    name: 'Salud y Belleza',
    icon: <MdFaceRetouchingNatural />,
    link: '/directorio-de-empresas?categories=Salud%20y%20Belleza'
  },
  {
    name: 'Educación y servicios públicos y sociales',
    icon: <RiPencilRulerLine />,
    link: '/directorio-de-empresas?categories=Educación%20y%20servicios%20públicos%20y%20sociales'
  },
  {
    name: 'Servicios Profesionales',
    icon: <IoIosPeople />,
    link: '/directorio-de-empresas?categories=Servicios%20Profesionales'
  },
  {
    name: 'Alojamiento y Turismo',
    icon: <BiHotel />,
    link: '/directorio-de-empresas?categories=Alojamiento%20y%20Turismo'
  },
  {
    name: 'Agropecuario, ganaderia, forestal y pesca.',
    icon: <GiFarmer />,
    link: '/directorio-de-empresas?categories=Agropecuario%2C%20ganaderia%2C%20forestal%20y%20pesca.'
  },
  {
    name: 'Medios',
    icon: <VscRadioTower />,
    link: '/directorio-de-empresas?categories=Medios'
  },
  {
    name: 'Arte, deporte, recreación y entretenimiento',
    icon: <MdPool />,
    link: '/directorio-de-empresas?categories=Arte%2C%20deporte%2C%20recreación%20y%20entretenimiento'
  },
  {
    name: 'Construcción',
    icon: <GrUserWorker />,
    link: '/directorio-de-empresas?categories=Construcción'
  },
  {
    name: 'Medio Ambiente y Reciclaje',
    icon: <FaRecycle />,
    link: '/directorio-de-empresas?categories=Medio%20Ambiente%20y%20Reciclaje'
  },
  {
    name: 'Energía, agua y gas',
    icon: <RiWaterFlashLine />,
    link: '/directorio-de-empresas?categories=Energía%2C%20agua%20y%20gas'
  },
  {
    name: 'Industrias',
    icon: <MdBusiness />,
    link: '/directorio-de-empresas?categories=Industrias'
  },
  {
    name: 'Asociaciones',
    icon: <FaRegHandshake />,
    link: '/directorio-de-empresas?categories=Asociaciones'
  },
  {
    name: 'Almacenamiento y Logistica',
    icon: <FaWarehouse />,
    link: '/directorio-de-empresas?categories=Almacenamiento%20y%20Logistica'
  },
  {
    name: 'Otros',
    icon: <IoListCircleOutline />,
    link: '/directorio-de-empresas?categories=Otros'
  }
]
