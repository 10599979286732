import React, {memo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Actions = ({children, className}) => {
  return (<div className={classNames(
    'absolute -top-8 lg:top-0 xl:top-4 right-0 lg:right-6'
    , className)}>
    {children}
  </div>);
};

const ItemCard = ({children, hoverable, className, bordered, actions}) => {
  return (
    <div className={classNames(
      {'hover:shadow-lg cursor-pointer': hoverable},
      {'border border-solid border-gray-200 rounded p-3 lg:p-6': bordered},
      'w-full text-black relative'
      , className)}>
      {actions && <Actions>{actions}</Actions>}
      <div>{children}</div>
    </div>
  );

};

export default memo(ItemCard);

ItemCard.propTypes = {
  actions: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  hoverable: PropTypes.bool,
  bordered: PropTypes.bool
};
ItemCard.defaultProps = {
  bordered: true
};

Actions.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};
