import React, {memo} from 'react';
import PropTypes from 'prop-types';

export const PRICE_TYPE = {
  NO_PRICE: 'NO_PRICE',
  RANGE: 'RANGE',
  FIXED: 'FIXED'
};

const Price = ({ price }) => {
  const { type } = price;
  let content = 0;

  switch (type) {
    case PRICE_TYPE.RANGE: {
      if (price?.priceFrom && price?.priceTo) {
        content = `Entre ${price?.priceFrom} CUP y ${price?.priceTo} CUP`;
      }
      if (price?.priceFrom && !price?.priceTo) {
        content = `Desde ${price?.priceFrom} CUP`;
      }
      if (price?.priceTo && !price?.priceFrom) {
        content = `Hasta ${price?.priceTo} CUP`;
      }
      break;
    }
    case PRICE_TYPE.FIXED: {
      content = `${price?.price} CUP`;
      break;
    }
    default:
      return null;
  }

  return (
    <p className={'text-gray-500 mb-2 leading-6'}>{content}</p>
  );
};

export default memo(Price);

Price.propTypes = {
  price: PropTypes.object,
};

Price.defaultProps = {
  price: {}
};
