import {HiOutlinePhone} from 'react-icons/hi';
import {IoAt} from 'react-icons/io5';
import {AiOutlineApartment, AiOutlineGlobal, AiOutlineTag} from 'react-icons/ai';
import React from 'react';
import classNames from 'classnames';
import {ORG_FORMS_COLORS} from '../../constants/orgForms';
import {businessCategories} from '../BusinessSummary/businessCategory';
import { renderProvinceByMunicipality } from '../../utils/location'

const handleInfoClick = (e) => {
  e.stopPropagation();
};

const findIconCategory = (cat) => {
  const category = businessCategories.find(item => item?.name === cat);
  return category?.icon
}

const findCategoryLink = (cat) => {
  const category = businessCategories.find(item => item?.name === cat);
  return category?.link
};

export const renderPones = (telefono_principal = []) => {
  return telefono_principal?.map(phone => (
    <span key={phone} className={'mr-3 flex items-center hover:text-blue-7'}>
      <span className={'mr-2'}><HiOutlinePhone/></span>
      <span>{phone}</span>
    </span>
  ));
};

export const renderMails = (correo_electronico_principal = []) => {
  return correo_electronico_principal?.map(mail => (
    <span key={mail} className={'mr-3 flex items-center hover:text-blue-7'}>
      <span className={'mr-2'}><IoAt/></span>
      <span>{mail}</span>
    </span>
  ));
};
export const renderWebpage = (webpage) => {
  if (webpage)
    return (<span className={'mr-3 flex items-center hover:text-blue-7'}>
      <span className={'mr-2'}><AiOutlineGlobal/></span>
      <span>{webpage}</span>
    </span>);
  return '';
};

export const renderCategory = (category, index) => {
  return (
    <div key={index} className={'flex items-center mt-1'}>
      <AiOutlineTag className={'mr-2 min-w-4 w-4'}/>
      {category}
    </div>
  )
}

export const renderIconPlusCategory = (category, index) => {
  return (
    <a onClick={handleInfoClick} href={findCategoryLink(category)}>
      <div key={index} className={'flex items-center mt-1 cursor-pointer mr-6'}>
        <span className={'mr-3 min-w-4 w-4 text-xl'}>{findIconCategory(category)}</span>
        {category}
      </div>
    </a>
  )
}

export const renderActivity = (activity, index) => {
  if (activity.nombre === 'Contribución a la Seguridad Social') //todo: improve this
    return '';
  return (
    <div key={index} className={'flex items-center mt-1'}>
      <AiOutlineTag className={'mr-2 min-w-3'}/>
      {activity.nombre}
    </div>
  );
};

export const renderOrgForm = (forma_organizativa = {}) => {
  return (
    <div className={'flex items-center mt-1'}>
      <AiOutlineApartment className={
        classNames('mr-2',
          ORG_FORMS_COLORS[forma_organizativa.nombre] || 'text-green-8')}/>
      {forma_organizativa.nombre}
    </div>
  );
};


export const renderCategories = (business_categories = []) => {
  return business_categories?.map(renderCategory)
};

export const renderCategoriesWithIcons = (business_categories = []) => {
  return (
    <div className='flex flex-wrap'>
      {business_categories?.map(renderIconPlusCategory)}
    </div>
  )
};

export const renderActivities = (lista_actividad = []) => {
  return lista_actividad?.map(renderActivity);
};

export const renderLocation = (municipio) => {
  return (
    <div className='flex'>
      <span className={'mr-2'}>{municipio?.abreviatura},</span>
      <span>{renderProvinceByMunicipality(municipio?.dpa)}</span>
    </div>
  )
};

