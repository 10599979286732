import React, {memo} from 'react';
import PropTypes from 'prop-types';
import ItemCard from '../ItemCard';
import {Descriptions} from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';
import classNames from 'classnames';

const DescriptionsSt = styled(Descriptions)`
  div.ant-descriptions-header .ant-descriptions-title {
    font-size: 15px;
    font-weight: bold;
  }

  tr.ant-descriptions-row, .ant-descriptions-view {
    border: none !important;
  }

  .ant-descriptions-item-content, .ant-descriptions-item-label {
    padding: 4px 0px 4px 0 !important;
    border: none !important;
    vertical-align: top !important;
  }

  th.ant-descriptions-item-label {
    font-weight: 500;
    background-color: transparent !important;
  }

`;


const DefaultWidget = ({value}) => value || '-';

const renderItem = (item, business) => {
  const value = get(business, item.field);
  const Widget = item.widget || DefaultWidget;
  const valueContent = item.render ? item.render(value) : value;
  if (item.nullHidden && (value === undefined || value === null))
    return null;

  return (
    <Descriptions.Item label={item.label} key={item.field}>
      <Widget value={valueContent} field={item}/>
    </Descriptions.Item>
  );
};
const renderItems = (items = [], business = {}) => {
  return items.map((item) => renderItem(item, business));
};

const isMobile = () => {
  if (typeof window === 'object') {
    return document.documentElement.clientWidth < 790;
  }
  return true;
};

const layout = isMobile() ? 'vertical' : 'horizontal';

const BusinessInfo = ({title, className, gray, info, business, actions}) => {

  return (
    <ItemCard className={classNames(className, {'bg-gray-50': gray})} actions={actions}>
      <DescriptionsSt title={title} column={1} bordered labelStyle={{maxWidth: 200, width: 200}}
        layout={layout}>
        {renderItems(info, business)}
      </DescriptionsSt>
    </ItemCard>
  );

};

export default memo(BusinessInfo);

BusinessInfo.propTypes = {
  business: PropTypes.object,
  actions: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
  gray: PropTypes.bool,
  info: PropTypes.array
};
