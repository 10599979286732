import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { getFromSource, SERVICE_PLACEHOLDER } from '../../utils/getImages';
import Price from './Price';

const Image = styled.div`
  min-height: 180px;
  background: #c4c0c0 url("${({src}) => src}") no-repeat center;
  background-size: cover;
`;

const Service = ({
  service,
  onClick,
}) => {

  const handleClick = useMemo(() => {
    return onClick ? () => onClick(service) : undefined;
  }, [onClick, service]);

  return (
    <div className={classNames('relative shadow-xl flex rounded-lg', {'cursor-pointer': onClick})}
      onClick={handleClick}>
      <div className="bg-white flex w-full flex-col rounded-lg">
        <Image className={'rounded-tl rounded-tr'} src={service?.image ? getFromSource(service?.image) : SERVICE_PLACEHOLDER}/>
        <div className="flex flex-col p-6 w-full h-full">
          <div className={'flex-grow'}>
            <h2 className="text-gray-900 text-lg font-semibold leading-tight ">
              {service?.name}
            </h2>
            <div className="text-base mb-4 leading-tight">
              {service?.description}
            </div>
            <Price price={{
              price: service?.price,
              priceFrom: service?.priceFrom,
              priceTo: service?.priceTo,
              type: service?.priceType
            }}/>
          </div>
          {service?.url && <a onClick={e => e.stopPropagation()} href={service?.url} target="_blank" rel="noopener noreferrer">VER MÁS</a>}
        </div>
      </div>
    </div>
  );
};

export default memo(Service);

Service.propTypes = {
  EditIcon: PropTypes.any,
  RemoveIcon: PropTypes.any,
  actions: PropTypes.any,
  cacheKey: PropTypes.string,
  className: PropTypes.string,
  editAction: PropTypes.func,
  isLoading: PropTypes.bool,
  showDefaultActions: PropTypes.bool,
  removeAction: PropTypes.func,
  onClick: PropTypes.func,
  service: PropTypes.object
};
Service.defaultProps = {
  showDefaultActions: false
};
