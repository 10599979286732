import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import BusinessInfo from '../BusinessInfo';
import {renderActivities, renderCategoriesWithIcons, renderLocation} from '../Render/renders';
import {BooleanTag} from '../Widgets/Tag';
import WorkTime from '../WorkTime';


const info = [
  //empresa
  {
    label: 'Entidad rectora',
    field: 'organismo_direccion.nombre',
    nullHidden: true
  },
  {
    label: 'Estado',
    field: 'perfeccionamiento',
    widget: BooleanTag,
    nullHidden: true
  },
  //mipyme
  // {
  //   label: 'Clasificación MIPYME',
  //   field: 'clasificacion_mipyme',
  //   nullHidden: true
  // },
  // {
  //   label: 'Incubado en parque científico',
  //   field: 'incubado_parque_cientifico_tecnologico',
  //   widget: BooleanTag,
  //   nullHidden: true
  // },
  // {
  //   label: 'Exportador',
  //   field: 'ha_exportado',
  //   widget: BooleanTag,
  //   nullHidden: true
  // },

  //todos
  // {
  //   label: 'Empleados',
  //   field: 'cnt_empleado',
  //   nullHidden: true
  // },
  {
    label: 'Localización',
    field: 'municipio',
    render: renderLocation,
    nullHidden: true
  },
  {
    label: 'Categorías',
    field: 'business_categories',
    render: renderCategoriesWithIcons,
    nullHidden: true
  },
  {
    label: 'Actividades',
    field: 'lista_actividad',
    render: renderActivities,
    nullHidden: true
  },
  {
    label: <div>Horario</div>,
    field: 'workingHours',
    widget: WorkTime,
    nullHidden: true
  }
];

const BusinessSummary = ({className, actions, business, gray}) => {

  return (
    <BusinessInfo
      className={className}
      gray={gray}
      actions={actions}
      info={info}
      business={business}
    />
  );

};

export default memo(BusinessSummary);

BusinessSummary.propTypes = {
  className: PropTypes.string,
  gray: PropTypes.bool,
  actions: PropTypes.any,
  business: PropTypes.object
};
