import { structure } from '@dofleini/location';
import compose from 'lodash/fp/compose';
import flattenFP from 'lodash/fp/flatten';
import mapFP from 'lodash/fp/map';
import valuesFP from 'lodash/fp/values';
import map from 'lodash/map';
import React, { Fragment } from 'react';

export const provinceOptions = map(structure.PROVINCES, ({ name: label, acronym: value }) => ({ label, value }));

export const municipalityOptions = compose(
  mapFP(({ name: label, code: value }) => ({ label, value })),
  flattenFP,
  valuesFP,
)(structure.MUNICIPALITIES);

export const municipalityOptionsByProvince = (province) => {
  if (province && !structure.MUNICIPALITIES[province]) return [];
  if (!province) return municipalityOptions;
  return compose(
    mapFP(({ name: label, code: value }) => ({ label, value })),
    flattenFP,
    valuesFP,
  )(structure.MUNICIPALITIES[province]);
};

export const renderProvince = (value) => {
  return structure.findProvinceByAcronym(value)?.name || <Fragment/>;
};

export const getProvinceByAcronym = (value) => {
  if (!value) return null;
  return structure.findProvinceByAcronym(value);
};

export const renderProvinceByMunicipality = (value) => {
  return structure.findProvinceByMunicipalityCode(value)?.name;
};

export const getMunicipalityByCode = (value) => {
  if (!value) return null;
  return structure.findMunicipalityByCode(value);
};

export const getProvinceByCode = (value) => {
  if (!value) return null;
  return structure.findProvinceByCode(value);
};

export const renderMunicipality = (code) => structure.findMunicipalityByCode(code)?.name || <Fragment/>;
