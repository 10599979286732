import React, {memo, useMemo} from 'react';
import {Tag as AntTag} from 'antd';
import PropTypes from 'prop-types';

const getColor = (value, {colors, defaultColor},) => {
  if (colors) {
    return colors[value] || defaultColor;
  }
  return defaultColor;
};

const Tag = ({value, field}) => {

  const color = getColor(value, field);

  return (
    <AntTag color={color}>{value}</AntTag>
  );
};

const booleanFieldSettings = {
  colors: {
    Sí: 'blue',
    No: 'orange'
  }
};
export const BooleanTag = ({value}) => {

  const valueContent = useMemo(() => {
    if (typeof value === 'boolean')
      return value ? 'Sí' : 'No';
    if (value == '0' || value == '1')
      return value == '1' ? 'Sí' : 'No';
  }, [value]);

  return (
    <Tag value={valueContent} field={booleanFieldSettings}/>
  );
};

export default memo(Tag);

Tag.propTypes = {
  field: PropTypes.object,
  value: PropTypes.any
};

BooleanTag.propTypes = {
  value: PropTypes.any
};
