export const ACTIVE_LOCATION_REMOTE = false;
export const ONLY_CU = true;

// codigo de cuba
export const CU = '53';

export const CU_ITEM = {
    name: {
        es: 'Cuba',
        en: 'Cuba',
        fr: 'Cuba',
    },
    iso2: 'CU',
    iso3: 'CUB',
    phoneCode: ['53'],
    country: '53',
    code: '1053',
    region: 1,
    type: 'COUNTRY',
};

export const COUNTRY = [CU_ITEM];

export const PROVINCES = [
    {
        name: {
            es: 'Pinar del Río',
            en: 'Pinar del Río',
            fr: 'Pinar del Río',
        },
        country: '53',
        state: '21',
        code: '5321',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'Artemisa',
            en: 'Artemisa',
            fr: 'Artemisa',
        },
        country: '53',
        state: '22',
        code: '5322',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'La Habana',
            en: 'La Habana',
            fr: 'La Habana',
        },
        country: '53',
        state: '23',
        code: '5323',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'Mayabeque',
            en: 'Mayabeque',
            fr: 'Mayabeque',
        },
        country: '53',
        state: '24',
        code: '5324',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'Matanzas',
            en: 'Matanzas',
            fr: 'Matanzas',
        },
        country: '53',
        state: '25',
        code: '5325',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'Villa Clara',
            en: 'Villa Clara',
            fr: 'Villa Clara',
        },
        country: '53',
        state: '26',
        code: '5326',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'Cienfuegos',
            en: 'Cienfuegos',
            fr: 'Cienfuegos',
        },
        country: '53',
        state: '27',
        code: '5327',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'Sancti Spíritus',
            en: 'Sancti Spíritus',
            fr: 'Sancti Spíritus',
        },
        country: '53',
        state: '28',
        code: '5328',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'Ciego de Ávila',
            en: 'Ciego de Ávila',
            fr: 'Ciego de Ávila',
        },
        country: '53',
        state: '29',
        code: '5329',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'Camagüey',
            en: 'Camagüey',
            fr: 'Camagüey',
        },
        country: '53',
        state: '30',
        code: '5330',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'Las Tunas',
            en: 'Las Tunas',
            fr: 'Las Tunas',
        },
        country: '53',
        state: '31',
        code: '5331',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'Holguín',
            en: 'Holguín',
            fr: 'Holguín',
        },
        country: '53',
        state: '32',
        code: '5332',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'Granma',
            en: 'Granma',
            fr: 'Granma',
        },
        state: '33',
        code: '5333',
        region: 1,
        country: '53',
        type: 'STATE',
    },
    {
        name: {
            es: 'Santiago de Cuba',
            en: 'Santiago de Cuba',
            fr: 'Santiago de Cuba',
        },
        country: '53',
        state: '34',
        code: '5334',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'Guantánamo',
            en: 'Guantánamo',
            fr: 'Guantánamo',
        },
        country: '53',
        state: '35',
        code: '5335',
        region: 1,
        type: 'STATE',
    },
    {
        name: {
            es: 'Isla de la Juventud',
            en: 'Isla de la Juventud',
            fr: 'Isla de la Juventud',
        },
        country: '53',
        state: '36',
        code: '5336',
        region: 1,
        type: 'STATE',
    },
];

export const PRI = [
    {
        code: '532101',
        municipality: '01',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '21',
        name: {
            es: 'Sandino',
            en: 'Sandino',
            fr: 'Sandino',
        },
    },
    {
        code: '532102',
        municipality: '02',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '21',
        name: {
            es: 'Mantua',
            en: 'Mantua',
            fr: 'Mantua',
        },
    },
    {
        code: '532103',
        municipality: '03',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '21',
        name: {
            es: 'Minas de Matahambre',
            en: 'Minas de Matahambre',
            fr: 'Minas de Matahambre',
        },
    },
    {
        code: '532104',
        municipality: '04',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '21',
        name: {
            es: 'Viñales',
            en: 'Viñales',
            fr: 'Viñales',
        },
    },
    {
        code: '532105',
        municipality: '05',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '21',
        name: {
            es: 'La Palma',
            en: 'La Palma',
            fr: 'La Palma',
        },
    },
    {
        code: '532106',
        municipality: '06',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '21',
        name: {
            es: 'Los Palacios',
            en: 'Los Palacios',
            fr: 'Los Palacios',
        },
    },
    {
        code: '532107',
        municipality: '07',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '21',
        name: {
            es: 'Consolación del sur',
            en: 'Consolación del sur',
            fr: 'Consolación del sur',
        },
    },
    {
        code: '532108',
        municipality: '08',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '21',
        name: {
            es: 'Pinar del Río',
            en: 'Pinar del Río',
            fr: 'Pinar del Río',
        },
    },
    {
        code: '532109',
        municipality: '09',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '21',
        name: {
            es: 'San Luis',
            en: 'San Luis',
            fr: 'San Luis',
        },
    },
    {
        code: '532110',
        municipality: '10',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '21',
        name: {
            es: 'San Juan y Martínez',
            en: 'San Juan y Martínez',
            fr: 'San Juan y Martínez',
        },
    },
    {
        code: '532111',
        municipality: '11',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '21',
        name: {
            es: 'Guane',
            en: 'Guane',
            fr: 'Guane',
        },
    },
];

export const ART = [
    {
        code: '532201',
        municipality: '01',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '22',
        name: {
            es: 'Bahía Honda',
            en: 'Bahía Honda',
            fr: 'Bahía Honda',
        },
    },
    {
        code: '532202',
        municipality: '02',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '22',
        name: {
            es: 'Mariel',
            en: 'Mariel',
            fr: 'Mariel',
        },
    },
    {
        code: '532203',
        municipality: '03',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '22',
        name: {
            es: 'Guanajay',
            en: 'Guanajay',
            fr: 'Guanajay',
        },
    },
    {
        code: '532204',
        municipality: '04',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '22',
        name: {
            es: 'Caimito',
            en: 'Caimito',
            fr: 'Caimito',
        },
    },
    {
        code: '532205',
        municipality: '05',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '22',
        name: {
            es: 'Bauta',
            en: 'Bauta',
            fr: 'Bauta',
        },
    },
    {
        code: '532206',
        municipality: '06',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '22',
        name: {
            es: 'San Antonio de los Baños',
            en: 'San Antonio de los Baños',
            fr: 'San Antonio de los Baños',
        },
    },
    {
        code: '532207',
        municipality: '07',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '22',
        name: {
            es: 'Güira de Melena',
            en: 'Güira de Melena',
            fr: 'Güira de Melena',
        },
    },
    {
        code: '532208',
        municipality: '08',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '22',
        name: {
            es: 'Alquízar',
            en: 'Alquízar',
            fr: 'Alquízar',
        },
    },
    {
        code: '532209',
        municipality: '09',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '22',
        name: {
            es: 'Artemisa',
            en: 'Artemisa',
            fr: 'Artemisa',
        },
    },
    {
        code: '532210',
        municipality: '10',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '22',
        name: {
            es: 'Candelaria',
            en: 'Candelaria',
            fr: 'Candelaria',
        },
    },
    {
        code: '532211',
        municipality: '11',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '22',
        name: {
            es: 'San Cristóbal',
            en: 'San Cristóbal',
            fr: 'San Cristóbal',
        },
    },
];

export const CAM = [
    {
        name: {
            es: 'Nuevitas',
            en: 'Nuevitas',
            fr: 'Nuevitas',
        },
        code: '533005',
        municipality: '05',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '30',
    },
    {
        name: {
            es: 'Esmeralda',
            en: 'Esmeralda',
            fr: 'Esmeralda',
        },
        code: '533002',
        municipality: '02',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '30',
    },
    {
        name: {
            es: 'Sierra de Cubitas',
            en: 'Sierra de Cubitas',
            fr: 'Sierra de Cubitas',
        },
        code: '533003',
        municipality: '03',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '30',
    },
    {
        name: {
            es: 'Minas',
            en: 'Minas',
            fr: 'Minas',
        },
        code: '533004',
        municipality: '04',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '30',
    },
    {
        name: {
            es: 'Carlos Manuel de Céspedes',
            en: 'Carlos Manuel de Céspedes',
            fr: 'Carlos Manuel de Céspedes',
        },
        code: '533001',
        municipality: '01',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '30',
    },
    {
        name: {
            es: 'Florida',
            en: 'Florida',
            fr: 'Florida',
        },
        code: '533009',
        municipality: '09',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '30',
    },
    {
        name: {
            es: 'Camagüey',
            en: 'Camagüey',
            fr: 'Camagüey',
        },
        code: '533008',
        municipality: '08',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '30',
    },
    {
        name: {
            es: 'Guáimaro',
            en: 'Guáimaro',
            fr: 'Guáimaro',
        },
        code: '533006',
        municipality: '06',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '30',
    },
    {
        name: {
            es: 'Sibanicú',
            en: 'Sibanicú',
            fr: 'Sibanicú',
        },
        code: '533007',
        municipality: '07',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '30',
    },
    {
        name: {
            es: 'Vertientes',
            en: 'Vertientes',
            fr: 'Vertientes',
        },
        code: '533010',
        municipality: '10',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '30',
    },
    {
        name: {
            es: 'Jimaguayú',
            en: 'Jimaguayú',
            fr: 'Jimaguayú',
        },
        code: '533011',
        municipality: '11',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '30',
    },
    {
        name: {
            es: 'Santa Cruz del Sur',
            en: 'Santa Cruz del Sur',
            fr: 'Santa Cruz del Sur',
        },
        code: '533013',
        municipality: '13',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '30',
    },
    {
        name: {
            es: 'Najasa',
            en: 'Najasa',
            fr: 'Najasa',
        },
        code: '533012',
        municipality: '12',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '30',
    },
];

export const CAV = [
    {
        name: {
            es: 'Chambas',
            en: 'Chambas',
            fr: 'Chambas',
        },
        code: '532901',
        municipality: '01',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '29',
    },
    {
        name: {
            es: 'Morón',
            en: 'Morón',
            fr: 'Morón',
        },
        code: '532902',
        municipality: '02',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '29',
    },
    {
        name: {
            es: 'Florencia',
            en: 'Florencia',
            fr: 'Florencia',
        },
        code: '532906',
        municipality: '06',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '29',
    },
    {
        name: {
            es: 'Ciro Redondo',
            en: 'Ciro Redondo',
            fr: 'Ciro Redondo',
        },
        code: '532905',
        municipality: '05',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '29',
    },
    {
        name: {
            es: 'Bolivia',
            en: 'Bolivia',
            fr: 'Bolivia',
        },
        code: '532903',
        municipality: '03',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '29',
    },
    {
        name: {
            es: 'Primero de Enero',
            en: 'Primero de Enero',
            fr: 'Primero de Enero',
        },
        code: '532904',
        municipality: '04',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '29',
    },
    {
        name: {
            es: 'Majagua',
            en: 'Majagua',
            fr: 'Majagua',
        },
        code: '532907',
        municipality: '07',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '29',
    },
    {
        name: {
            es: 'Ciego de Ávila',
            en: 'Ciego de Ávila',
            fr: 'Ciego de Ávila',
        },
        code: '532908',
        municipality: '08',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '29',
    },
    {
        name: {
            es: 'Baraguá',
            en: 'Baraguá',
            fr: 'Baraguá',
        },
        code: '532910',
        municipality: '10',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '29',
    },
    {
        name: {
            es: 'Venezuela',
            en: 'Venezuela',
            fr: 'Venezuela',
        },
        code: '532909',
        municipality: '09',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '29',
    },
];

export const CFG = [
    {
        name: {
            es: 'Rodas',
            en: 'Rodas',
            fr: 'Rodas',
        },
        code: '532702',
        municipality: '02',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '27',
    },
    {
        name: {
            es: 'Lajas',
            en: 'Lajas',
            fr: 'Lajas',
        },
        code: '532704',
        municipality: '04',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '27',
    },
    {
        name: {
            es: 'Aguada de Pasajeros',
            en: 'Aguada de Pasajeros',
            fr: 'Aguada de Pasajeros',
        },
        code: '532701',
        municipality: '01',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '27',
    },
    {
        name: {
            es: 'Palmira',
            en: 'Palmira',
            fr: 'Palmira',
        },
        code: '532703',
        municipality: '03',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '27',
    },
    {
        name: {
            es: 'Cruces',
            en: 'Cruces',
            fr: 'Cruces',
        },
        code: '532705',
        municipality: '05',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '27',
    },
    {
        name: {
            es: 'Cumanayagua',
            en: 'Cumanayagua',
            fr: 'Cumanayagua',
        },
        code: '532706',
        municipality: '06',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '27',
    },
    {
        name: {
            es: 'Cienfuegos',
            en: 'Cienfuegos',
            fr: 'Cienfuegos',
        },
        code: '532707',
        municipality: '07',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '27',
    },
    {
        name: {
            es: 'Abreus',
            en: 'Abreus',
            fr: 'Abreus',
        },
        code: '532708',
        municipality: '08',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '27',
    },
];

export const GRM = [
    {
        name: {
            es: 'Río Cauto',
            en: 'Río Cauto',
            fr: 'Río Cauto',
        },
        code: '533301',
        municipality: '01',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '33',
    },
    {
        name: {
            es: 'Cauto Cristo',
            en: 'Cauto Cristo',
            fr: 'Cauto Cristo',
        },
        code: '533302',
        municipality: '02',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '33',
    },
    {
        name: {
            es: 'Bayamo',
            en: 'Bayamo',
            fr: 'Bayamo',
        },
        code: '533304',
        municipality: '04',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '33',
    },
    {
        name: {
            es: 'Jiguaní',
            en: 'Jiguaní',
            fr: 'Jiguaní',
        },
        code: '533303',
        municipality: '03',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '33',
    },
    {
        name: {
            es: 'Yara',
            en: 'Yara',
            fr: 'Yara',
        },
        code: '533305',
        municipality: '05',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '33',
    },
    {
        name: {
            es: 'Manzanillo',
            en: 'Manzanillo',
            fr: 'Manzanillo',
        },
        code: '533306',
        municipality: '06',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '33',
    },
    {
        name: {
            es: 'Guisa',
            en: 'Guisa',
            fr: 'Guisa',
        },
        code: '533313',
        municipality: '13',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '33',
    },
    {
        name: {
            es: 'Buey Arriba',
            en: 'Buey Arriba',
            fr: 'Buey Arriba',
        },
        code: '533312',
        municipality: '12',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '33',
    },
    {
        name: {
            es: 'Bartolomé Masó',
            en: 'Bartolomé Masó',
            fr: 'Bartolomé Masó',
        },
        code: '533311',
        municipality: '11',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '33',
    },
    {
        name: {
            es: 'Media Luna',
            en: 'Media Luna',
            fr: 'Media Luna',
        },
        code: '533308',
        municipality: '08',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '33',
    },
    {
        name: {
            es: 'Niquero',
            en: 'Niquero',
            fr: 'Niquero',
        },
        code: '533309',
        municipality: '09',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '33',
    },
    {
        name: {
            es: 'Pilón',
            en: 'Pilón',
            fr: 'Pilón',
        },
        code: '533310',
        municipality: '10',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '33',
    },
    {
        name: {
            es: 'Campechuela',
            en: 'Campechuela',
            fr: 'Campechuela',
        },
        code: '533307',
        municipality: '07',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '33',
    },
];

export const GTM = [
    {
        name: {
            es: 'Guantánamo',
            en: 'Guantánamo',
            fr: 'Guantánamo',
        },
        code: '533509',
        municipality: '09',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '35',
    },
    {
        name: {
            es: 'Yateras',
            en: 'Yateras',
            fr: 'Yateras',
        },
        code: '533503',
        municipality: '03',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '35',
    },
    {
        name: {
            es: 'El Salvador',
            en: 'El Salvador',
            fr: 'El Salvador',
        },
        code: '533501',
        municipality: '01',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '35',
    },
    {
        name: {
            es: 'Manuel Tames',
            en: 'Manuel Tames',
            fr: 'Manuel Tames',
        },
        code: '533502',
        municipality: '02',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '35',
    },
    {
        name: {
            es: 'Niceto Pérez',
            en: 'Niceto Pérez',
            fr: 'Niceto Pérez',
        },
        code: '533510',
        municipality: '10',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '35',
    },
    {
        name: {
            es: 'Caimanera',
            en: 'Caimanera',
            fr: 'Caimanera',
        },
        code: '533508',
        municipality: '08',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '35',
    },
    {
        name: {
            es: 'San Antonio del Sur',
            en: 'San Antonio del Sur',
            fr: 'San Antonio del Sur',
        },
        code: '533507',
        municipality: '07',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '35',
    },
    {
        name: {
            es: 'Baracoa',
            en: 'Baracoa',
            fr: 'Baracoa',
        },
        code: '533504',
        municipality: '04',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '35',
    },
    {
        name: {
            es: 'Imías',
            en: 'Imías',
            fr: 'Imías',
        },
        code: '533506',
        municipality: '06',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '35',
    },
    {
        name: {
            es: 'Maisí',
            en: 'Maisí',
            fr: 'Maisí',
        },
        code: '533505',
        municipality: '05',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '35',
    },
];

export const HOL = [
    {
        name: {
            es: 'Banes',
            en: 'Banes',
            fr: 'Banes',
        },
        code: '533203',
        municipality: '03',
        state: '32',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Gibara',
            en: 'Gibara',
            fr: 'Gibara',
        },
        code: '533201',
        municipality: '01',
        state: '32',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Rafael Freyre',
            en: 'Rafael Freyre',
            fr: 'Rafael Freyre',
        },
        code: '533202',
        municipality: '02',
        state: '32',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Calixto García',
            en: 'Calixto García',
            fr: 'Calixto García',
        },
        code: '533207',
        municipality: '07',
        state: '32',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Holguín',
            en: 'Holguín',
            fr: 'Holguín',
        },
        code: '533206',
        municipality: '06',
        state: '32',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Báguanos',
            en: 'Báguanos',
            fr: 'Báguanos',
        },
        code: '533205',
        municipality: '05',
        state: '32',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Antilla',
            en: 'Antilla',
            fr: 'Antilla',
        },
        code: '533204',
        municipality: '04',
        state: '32',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Mayarí',
            en: 'Mayarí',
            fr: 'Mayarí',
        },
        code: '533211',
        municipality: '11',
        state: '32',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Cacocum',
            en: 'Cacocum',
            fr: 'Cacocum',
        },
        code: '533208',
        municipality: '08',
        state: '32',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Moa',
            en: 'Moa',
            fr: 'Moa',
        },
        code: '533214',
        municipality: '14',
        state: '32',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Frank País',
            en: 'Frank País',
            fr: 'Frank País',
        },
        code: '533212',
        municipality: '12',
        state: '32',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Cueto',
            en: 'Cueto',
            fr: 'Cueto',
        },
        code: '533210',
        municipality: '10',
        state: '32',
        country: '53',
    },
    {
        name: {
            es: 'Urbano Noris',
            en: 'Urbano Noris',
            fr: 'Urbano Noris',
        },
        code: '533209',
        municipality: '09',
        state: '32',
        country: '53',
    },
    {
        name: {
            es: 'Sagua de Tánamo',
            en: 'Sagua de Tánamo',
            fr: 'Sagua de Tánamo',
        },
        code: '533213',
        municipality: '13',
        state: '32',
        country: '53',
    },
];

export const ISJ = [
    {
        code: '534001',
        municipality: '01',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
        state: '40',
        name: {
            es: 'Isla de la Juventud',
            en: 'Isla de la Juventud',
            fr: 'Isla de la Juventud',
        },
    },
];

export const HAB = [
    {
        name: {
            es: 'Habana del Este',
            en: 'Habana del Este',
            fr: 'Habana del Este',
        },
        code: '532306',
        municipality: '06',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'Centro Habana',
            en: 'Centro Habana',
            fr: 'Centro Habana',
        },
        code: '532303',
        municipality: '03',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'Regla',
            en: 'Regla',
            fr: 'Regla',
        },
        code: '532305',
        municipality: '05',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'Plaza de la Revolución',
            en: 'Plaza de la Revolución',
            fr: 'Plaza de la Revolución',
        },
        code: '532302',
        municipality: '02',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'La Habana Vieja',
            en: 'La Habana Vieja',
            fr: 'La Habana Vieja',
        },
        code: '532304',
        municipality: '04',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'Cerro',
            en: 'Cerro',
            fr: 'Cerro',
        },
        code: '532310',
        municipality: '10',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'Diez de Octubre',
            en: 'Diez de Octubre',
            fr: 'Diez de Octubre',
        },
        code: '532309',
        municipality: '09',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'Guanabacoa',
            en: 'Guanabacoa',
            fr: 'Guanabacoa',
        },
        code: '532307',
        municipality: '07',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'San Miguel del Padrón',
            en: 'San Miguel del Padrón',
            fr: 'San Miguel del Padrón',
        },
        code: '532308',
        municipality: '08',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'Playa',
            en: 'Playa',
            fr: 'Playa',
        },
        code: '532301',
        municipality: '01',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'Arroyo Naranjo',
            en: 'Arroyo Naranjo',
            fr: 'Arroyo Naranjo',
        },
        code: '532314',
        municipality: '14',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'Boyeros',
            en: 'Boyeros',
            fr: 'Boyeros',
        },
        code: '532313',
        municipality: '13',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'Marianao',
            en: 'Marianao',
            fr: 'Marianao',
        },
        code: '532311',
        municipality: '11',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'Cotorro',
            en: 'Cotorro',
            fr: 'Cotorro',
        },
        code: '532315',
        municipality: '15',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
    {
        name: {
            es: 'La Lisa',
            en: 'La Lisa',
            fr: 'La Lisa',
        },
        code: '532312',
        municipality: '12',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '23',
    },
];

export const LTU = [
    {
        name: {
            es: 'Manatí',
            en: 'Manatí',
            fr: 'Manatí',
        },
        code: '533101',
        municipality: '01',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '31',
    },
    {
        name: {
            es: 'Jesús Menéndez',
            en: 'Jesús Menéndez',
            fr: 'Jesús Menéndez',
        },
        code: '533103',
        municipality: '03',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '31',
    },
    {
        name: {
            es: 'Puerto Padre',
            en: 'Puerto Padre',
            fr: 'Puerto Padre',
        },
        code: '533102',
        municipality: '02',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '31',
    },
    {
        name: {
            es: 'Las Tunas',
            en: 'Las Tunas',
            fr: 'Las Tunas',
        },
        code: '533105',
        municipality: '05',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '31',
    },
    {
        name: {
            es: 'Jobabo',
            en: 'Jobabo',
            fr: 'Jobabo',
        },
        code: '533106',
        municipality: '06',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '31',
    },
    {
        name: {
            es: 'Colombia',
            en: 'Colombia',
            fr: 'Colombia',
        },
        code: '533107',
        municipality: '07',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '31',
    },
    {
        name: {
            es: 'Amancio',
            en: 'Amancio',
            fr: 'Amancio',
        },
        code: '533108',
        municipality: '08',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '31',
    },
    {
        name: {
            es: 'Majibacoa',
            en: 'Majibacoa',
            fr: 'Majibacoa',
        },
        code: '533104',
        municipality: '04',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '31',
    },
];

export const MTZ = [
    {
        name: {
            es: 'Martí',
            en: 'Martí',
            fr: 'Martí',
        },
        code: '532503',
        municipality: '03',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '25',
    },
    {
        name: {
            es: 'Cárdenas',
            en: 'Cárdenas',
            fr: 'Cárdenas',
        },
        code: '532502',
        municipality: '02',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '25',
    },
    {
        name: {
            es: 'Matanzas',
            en: 'Matanzas',
            fr: 'Matanzas',
        },
        code: '532501',
        municipality: '01',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '25',
    },
    {
        name: {
            es: 'Limonar',
            en: 'Limonar',
            fr: 'Limonar',
        },
        code: '532508',
        municipality: '08',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '25',
    },
    {
        name: {
            es: 'Unión de Reyes',
            en: 'Unión de Reyes',
            fr: 'Unión de Reyes',
        },
        code: '532509',
        municipality: '09',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '25',
    },
    {
        name: {
            es: 'Perico',
            en: 'Perico',
            fr: 'Perico',
        },
        code: '532505',
        municipality: '05',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '25',
    },
    {
        name: {
            es: 'Jovellanos',
            en: 'Jovellanos',
            fr: 'Jovellanos',
        },
        code: '532506',
        municipality: '06',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '25',
    },
    {
        name: {
            es: 'Pedro Betancourt',
            en: 'Pedro Betancourt',
            fr: 'Pedro Betancourt',
        },
        code: '532507',
        municipality: '07',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '25',
    },
    {
        name: {
            es: 'Los Arabos',
            en: 'Los Arabos',
            fr: 'Los Arabos',
        },
        code: '532513',
        municipality: '13',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '25',
    },
    {
        name: {
            es: 'Colón',
            en: 'Colón',
            fr: 'Colón',
        },
        code: '532504',
        municipality: '04',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '25',
    },
    {
        name: {
            es: 'Jagüey Grande',
            en: 'Jagüey Grande',
            fr: 'Jagüey Grande',
        },
        code: '532511',
        municipality: '11',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '25',
    },
    {
        name: {
            es: 'Calimete',
            en: 'Calimete',
            fr: 'Calimete',
        },
        code: '532512',
        municipality: '12',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '25',
    },
    {
        name: {
            es: 'Cienaga de Zapata',
            en: 'Cienaga de Zapata',
            fr: 'Cienaga de Zapata',
        },
        code: '532510',
        municipality: '10',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '25',
    },
];

export const MAY = [
    {
        name: {
            es: 'Santa Cruz del Norte',
            en: 'Santa Cruz del Norte',
            fr: 'Santa Cruz del Norte',
        },
        code: '532404',
        municipality: '04',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '24',
    },
    {
        name: {
            es: 'San José de las Lajas',
            en: 'San José de las Lajas',
            fr: 'San José de las Lajas',
        },
        code: '532402',
        municipality: '02',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '24',
    },
    {
        name: {
            es: 'Jaruco',
            en: 'Jaruco',
            fr: 'Jaruco',
        },
        code: '532403',
        municipality: '03',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '24',
    },
    {
        name: {
            es: 'Madruga',
            en: 'Madruga',
            fr: 'Madruga',
        },
        code: '532405',
        municipality: '05',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '24',
    },
    {
        name: {
            es: 'Bejucal',
            en: 'Bejucal',
            fr: 'Bejucal',
        },
        code: '532401',
        municipality: '01',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '24',
    },
    {
        name: {
            es: 'Quivicán',
            en: 'Quivicán',
            fr: 'Quivicán',
        },
        code: '532411',
        municipality: '11',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '24',
    },
    {
        name: {
            es: 'Güines',
            en: 'Güines',
            fr: 'Güines',
        },
        code: '532408',
        municipality: '08',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '24',
    },
    {
        name: {
            es: 'San Nicolás',
            en: 'San Nicolás',
            fr: 'San Nicolás',
        },
        code: '532407',
        municipality: '07',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '24',
    },
    {
        name: {
            es: 'Nueva Paz',
            en: 'Nueva Paz',
            fr: 'Nueva Paz',
        },
        code: '532406',
        municipality: '06',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '24',
    },
    {
        name: {
            es: 'Batabanó',
            en: 'Batabanó',
            fr: 'Batabanó',
        },
        code: '532410',
        municipality: '10',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '24',
    },
    {
        name: {
            es: 'Melena del Sur',
            en: 'Melena del Sur',
            fr: 'Melena del Sur',
        },
        code: '532409',
        municipality: '09',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '24',
    },
];

export const SSP = [
    {
        name: {
            es: 'Trinidad',
            en: 'Trinidad',
            fr: 'Trinidad',
        },
        code: '532806',
        municipality: '06',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '28',
    },
    {
        name: {
            es: 'Yaguajay',
            en: 'Yaguajay',
            fr: 'Yaguajay',
        },
        code: '532801',
        municipality: '01',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '28',
    },
    {
        name: {
            es: 'Cabaiguán',
            en: 'Cabaiguán',
            fr: 'Cabaiguán',
        },
        code: '532804',
        municipality: '04',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '28',
    },
    {
        name: {
            es: 'Fomento',
            en: 'Fomento',
            fr: 'Fomento',
        },
        code: '532805',
        municipality: '05',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '28',
    },
    {
        name: {
            es: 'Taguasco',
            en: 'Taguasco',
            fr: 'Taguasco',
        },
        code: '532803',
        municipality: '03',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '28',
    },
    {
        name: {
            es: 'Jatibonico',
            en: 'Jatibonico',
            fr: 'Jatibonico',
        },
        code: '532802',
        municipality: '02',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '28',
    },
    {
        name: {
            es: 'Sancti Spíritus',
            en: 'Sancti Spíritus',
            fr: 'Sancti Spíritus',
        },
        code: '532807',
        municipality: '07',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '28',
    },
    {
        name: {
            es: 'La Sierpe',
            en: 'La Sierpe',
            fr: 'La Sierpe',
        },
        code: '532808',
        municipality: '08',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '28',
    },
];

export const VCL = [
    {
        name: {
            es: 'Sagua La Grande',
            en: 'Sagua La Grande',
            fr: 'Sagua La Grande',
        },
        code: '532603',
        municipality: '03',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '26',
    },
    {
        name: {
            es: 'Corralillo',
            en: 'Corralillo',
            fr: 'Corralillo',
        },
        code: '532601',
        municipality: '01',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '26',
    },
    {
        name: {
            es: 'Quemado de Güines',
            en: 'Quemado de Güines',
            fr: 'Quemado de Güines',
        },
        code: '532602',
        municipality: '02',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '26',
    },
    {
        name: {
            es: 'Santo Domingo',
            en: 'Santo Domingo',
            fr: 'Santo Domingo',
        },
        code: '532611',
        municipality: '11',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '26',
    },
    {
        name: {
            es: 'Cifuentes',
            en: 'Cifuentes',
            fr: 'Cifuentes',
        },
        code: '532610',
        municipality: '10',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '26',
    },
    {
        name: {
            es: 'Ranchuelo',
            en: 'Ranchuelo',
            fr: 'Ranchuelo',
        },
        code: '532612',
        municipality: '12',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '26',
    },
    {
        name: {
            es: 'Santa Clara',
            en: 'Santa Clara',
            fr: 'Santa Clara',
        },
        code: '532609',
        municipality: '09',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '26',
    },
    {
        name: {
            es: 'Manicaragua',
            en: 'Manicaragua',
            fr: 'Manicaragua',
        },
        code: '532613',
        municipality: '13',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '26',
    },
    {
        name: {
            es: 'Encrucijada',
            en: 'Encrucijada',
            fr: 'Encrucijada',
        },
        code: '532604',
        municipality: '04',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '26',
    },
    {
        name: {
            es: 'Camajuaní',
            en: 'Camajuaní',
            fr: 'Camajuaní',
        },
        code: '532605',
        municipality: '05',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '26',
    },
    {
        name: {
            es: 'Caibarién',
            en: 'Caibarién',
            fr: 'Caibarién',
        },
        code: '532606',
        municipality: '06',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '26',
    },
    {
        name: {
            es: 'Remedios',
            en: 'Remedios',
            fr: 'Remedios',
        },
        code: '532607',
        municipality: '07',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '26',
    },
    {
        name: {
            es: 'Placetas',
            en: 'Placetas',
            fr: 'Placetas',
        },
        code: '532608',
        municipality: '08',
        country: '53',
        region: 1,
        type: 'MUNICIPALITY',
        state: '26',
    },
];

export const SCU = [
    {
        name: {
            es: 'Segundo Frente',
            en: 'Segundo Frente',
            fr: 'Segundo Frente',
        },
        code: '533404',
        state: '34',
        municipality: '04',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Mella',
            en: 'Mella',
            fr: 'Mella',
        },
        code: '533402',
        state: '34',
        municipality: '02',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Contramaestre',
            en: 'Contramaestre',
            fr: 'Contramaestre',
        },
        code: '533401',
        state: '34',
        municipality: '01',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'San Luis',
            en: 'San Luis',
            fr: 'San Luis',
        },
        code: '533403',
        state: '34',
        municipality: '03',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Palma Soriano',
            en: 'Palma Soriano',
            fr: 'Palma Soriano',
        },
        code: '533407',
        state: '34',
        municipality: '07',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Songo La Maya',
            en: 'Songo La Maya',
            fr: 'Songo La Maya',
        },
        code: '533405',
        state: '34',
        municipality: '05',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Tercer Frente',
            en: 'Tercer Frente',
            fr: 'Tercer Frente',
        },
        code: '533408',
        state: '34',
        municipality: '08',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Santiago de Cuba',
            en: 'Santiago de Cuba',
            fr: 'Santiago de Cuba',
        },
        code: '533406',
        state: '34',
        municipality: '06',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
    },
    {
        name: {
            es: 'Guamá',
            en: 'Guamá',
            fr: 'Guamá',
        },
        code: '533409',
        state: '34',
        municipality: '09',
        region: 1,
        country: '53',
        type: 'MUNICIPALITY',
    },
];

export const MUNICIPALITIES_ENUM = {
    PRI,
    ART,
    HAB,
    MAY,
    MTZ,
    VCL,
    CFG,
    SSP,
    CAV,
    CAM,
    LTU,
    HOL,
    GRM,
    SCU,
    GTM,
    ISJ,
};

export const MUNICIPALITIES = [
    ...PRI,
    ...ART,
    ...HAB,
    ...MAY,
    ...MTZ,
    ...VCL,
    ...CFG,
    ...SSP,
    ...CAV,
    ...CAM,
    ...LTU,
    ...HOL,
    ...GRM,
    ...SCU,
    ...GTM,
    ...ISJ,
];

export const getCountryByCode = (code) => COUNTRY.find(({country}) => country === code)
export const getProvinceByCode = (country, code) => PROVINCES.find((state) => state.state === code && state.country===country)
export const getMunicipalityByCode = (state, code) => MUNICIPALITIES.find((municipality) => municipality.municipality === code && municipality.state===state)

const write = (value, separator) => value ? (value+separator) :'';
const getName = (value, locale = 'es', separator) => write(value?(value.name[locale] || value.name?.es):'', separator);

export const getAddress = (address, locale, hideAddress) => {
    if (!address) return '';
    const country = getCountryByCode(address.country)
    const state = getProvinceByCode(address.country,address.state)
    const municipality = getMunicipalityByCode(address.state,address.municipality);
    const separator= ', '
    return `${hideAddress?'':(write(address?.address, separator)||'')}${getName(municipality, locale, separator)}${getName(state, locale, separator)}${getName(country, locale, '')}`
}

export const LOCATION = [...COUNTRY, ...PROVINCES, ...MUNICIPALITIES];
