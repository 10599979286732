/* eslint-disable no-unused-vars */
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import TimeList from './TimeList';
import styled from 'styled-components';


const WorkTimeContainer = styled.div`
  .ant-collapse-header {
    span {
      padding-top: 0px !important;
    }

    padding: 0 0 5px 0 !important;
  }
  .ant-collapse-content-box {
    padding-bottom: unset !important;
  }
`;

const WorkTime = ({value}) => {

  return (
    <WorkTimeContainer>
      <TimeList value={value}/>
    </WorkTimeContainer>
  );

};

export default memo(WorkTime);

WorkTime.propTypes = {
  value: PropTypes.any
};
