import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import BusinessLogo from '../BusinessLogo';
import ItemCard from '../ItemCard';

const BusinessHeader = ({ nameReal, logo, forma_organizativa, slogan, description, actions }) => {

  return (
    <ItemCard actions={actions} bordered={false}>
      <div className={'flex flex-col md:flex-row'}>
        <div className={'mt-1 mr-3 lg:mr-5'}>
          <BusinessLogo logo={logo} nameReal={nameReal} className={'mb-4'}/>
        </div>
        <div className={'flex-grow flex flex-col '}>
          <div><Tag>{forma_organizativa.nombre}</Tag></div>
          <h1 className={'m-0 text-3xl font-bold leading-9 mr-16'}>{nameReal}</h1>
          <p className={'text-gray-500 mb-2 leading-6'}>{slogan}</p>
          <p className={'max-w-240 m-0 leading-6'}>{description}</p>
        </div>
      </div>
    </ItemCard>
  );

};

export default memo(BusinessHeader);

BusinessHeader.propTypes = {
  description: PropTypes.string,
  forma_organizativa: PropTypes.object,
  logo: PropTypes.string,
  nameReal: PropTypes.string,
  slogan: PropTypes.string,
  actions: PropTypes.any
};

BusinessHeader.defaultProps = {
  forma_organizativa: { nombre: '' }
};
