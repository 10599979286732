import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Avatar} from 'antd';
import {getFromSource, LOGO_PLACEHOLDER} from '../../utils/getImages';
import classNames from 'classnames';

const BusinessLogo = ({logo, nameReal, className}) => {
  return (
    <Avatar src={getFromSource(logo) || LOGO_PLACEHOLDER}
      className={classNames(className, 'border border-gray-300 border-solid')}
      size={90}
      alt={nameReal}
      shape={'square'}/>
  );

};
// <img src={getFromSource(logo) || LOGO_PLACEHOLDER} height={100} width={90}
//      alt={'Empresa Agrícola de la Habana'}/>
export default memo(BusinessLogo);

BusinessLogo.propTypes = {
  logo: PropTypes.string,
  className: PropTypes.string,
  nameReal: PropTypes.string
};
