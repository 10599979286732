import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {AiOutlinePlus} from 'react-icons/ai';
import Service from '../Service';
import classNames from 'classnames';


const ServiceAdd = ({onClick}) => {
  return (
    <div className={'relative shadow-xl flex rounded-lg cursor-pointer border-dashed border border-gray-300 min-h-56'}
      onClick={onClick}>
      <div
        className="bg-white flex w-full h-full justify-center items-center flex-col rounded-lg text-4xl text-gray-400 hover:text-gray-600">
        <AiOutlinePlus/>
      </div>
    </div>
  );
};

const ServiceList = ({
  xl,
  services,
  editAction,
  removeAction,
  isRemoving,
  EditIcon,
  RemoveIcon,
  onClick,
  onAdd,
  // grid,
  ...props
}) => {

  return (
    <div className={classNames('grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 ', {'xl:grid-cols-4': xl})}>
      {services.map((service, index) => (
        <Service isLoading={isRemoving} editAction={editAction} removeAction={removeAction} key={index}
          onClick={onClick}
          service={service} RemoveIcon={RemoveIcon} EditIcon={EditIcon} {...props}/>
      ))}
      {onAdd && <ServiceAdd onClick={onAdd}/>}
    </div>
  );

};

export default memo(ServiceList);

ServiceList.propTypes = {
  EditIcon: PropTypes.any,
  RemoveIcon: PropTypes.any,
  editAction: PropTypes.any,
  grid: PropTypes.object,
  isRemoving: PropTypes.bool,
  onAdd: PropTypes.func,
  onClick: PropTypes.func,
  removeAction: PropTypes.func,
  services: PropTypes.array,
  xl: PropTypes.bool
};

ServiceList.defaultProps = {
  grid: {gutter: [16, 16], xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 4, column: 4},
  xl: false
};

ServiceAdd.propTypes = {
  onClick: PropTypes.func
};
