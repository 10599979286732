import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Collapse, Space } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const { Panel } = Collapse;

const STATUS = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE'
};

const STATUS_TEXT = {
  OPEN: 'Abierto',
  CLOSE: 'Cerrado'
};
const STATUS_CLASS = {
  OPEN: 'text-primary',
  CLOSE: 'text-error'
};

const WEEK_DAYS = {
  MON: 'Lunes',
  TUES: 'Martes',
  WED: 'Miércoles',
  THURS: 'Jueves',
  FRI: 'Viernes',
  SAT: 'Sábado',
  SUN: 'Domingo',
};

const strTime2minutes = (cad) => {
  var tmp = cad.split(':');
  return parseInt(tmp[0]) * 60 + parseInt(tmp[1]);
};

const Header = ({ status, nextStatus }) => {
  return (
    <div>
      {status && <span className={classNames('mr-3', STATUS_CLASS[status])}>{STATUS_TEXT[status]}</span>}
      {nextStatus?.nexStatusComponent}
    </div>);
};

const CollapseSt = styled(Collapse)`
  .ant-collapse-content-box {
    padding-top: 0 !important;
    padding-left: 0 !important;
  }

  .ant-collapse-header, .ant-collapse-content-box {
    padding-left: 0 !important;
  }

  .ant-collapse-header {
    padding: 12px 16px !important;
    padding-left: 0 !important;
  }
`;

const TimeList = ({ value }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentDate = new Date();
  const defaultDay = currentDate.getDay();
  const currentTimeInMinutes = currentDate.getHours() * 60 + currentDate.getMinutes();
  const currentHour = currentDate.getHours();
  const [status, setStatus] = useState(STATUS.CLOSE);

  const currentDay = useMemo(() => {
    if (defaultDay === 0)
      return 7;
    return defaultDay;
  }, [defaultDay]);

  const currentVal = useMemo(() => {
    const day = defaultDay;
    const val = [...value];
    const path1 = val.splice(day - 1, 8 - day);
    return path1.concat(val);
  }, [defaultDay, value]);

  const getDayLastElementHour = useCallback((schedule, elem) => {
    if (schedule?.length > 0) {
      let lastOpenHour = schedule[0][elem];

      for (let interval of schedule) {
        if (strTime2minutes(interval[elem]) > strTime2minutes(lastOpenHour)) {
          lastOpenHour = interval[elem];
        }
      }
      return { lastOpenHour };
    }
    return { lastOpenHour: null, lastOpenHourVal: null };
  }, []);

  const getDayFirstElementHours = useCallback((schedule, elem) => {
    if (schedule?.length > 0) {
      let firstElementHourVal = strTime2minutes(schedule[0][elem]);
      let firstElementHour = schedule[0][elem];

      for (let interval of schedule) {
        if (strTime2minutes(interval[elem]) < firstElementHourVal) {
          firstElementHourVal = strTime2minutes(interval[elem]);
          firstElementHour = interval[elem];
        }
      }
      return { firstElementHour, firstElementHourVal };
    }
    return { firstElementHour: null, firstElementHourVal: null };
  }, []);

  const getNextElementHour = useCallback((day, elem) => {
    if (day?.schedule?.length > 0) {
      let hour = null;
      const { lastOpenHour } = getDayLastElementHour(day?.schedule, elem);
      if (currentTimeInMinutes < strTime2minutes(lastOpenHour)) {
        let min = currentTimeInMinutes;
        for (let interval of day.schedule) {
          const el = strTime2minutes(interval[elem]);
          if (currentTimeInMinutes <= el) {
            if (el - currentTimeInMinutes < min) {
              min = el - currentTimeInMinutes;
              hour = interval[elem];
            }
          }
        }
      }
      return hour;
    }
  }, [currentTimeInMinutes, getDayLastElementHour]);

  const getNextOpenStatus = useCallback(() => {
    let startHour = getNextElementHour(currentVal[0], 'from');
    if (startHour) {
      return {
        nexStatusDay: currentVal[0],
        hour: startHour,
        nexStatusComponent:
          <span>{`Abre ${currentVal[0]?.id == currentDay ? 'hoy' : 'el ' + WEEK_DAYS[currentVal[0]?.key]} a las ${startHour}`}</span>
      };
    } else {
      for (let i = 1; i < currentVal?.length; i++) {
        const day = currentVal[i];
        if (day?.value) {
          if (day?.fullDay) {
            const startHour = '0:00';
            return {
              nexStatusDay: day,
              hour: startHour,
              nexStatusComponent:
                <span>{`Abre ${day?.id == currentDay ? 'hoy' : 'el ' + WEEK_DAYS[day?.key]} a las ${startHour}`}</span>
            };
          } else {
            let { firstElementHour } = getDayFirstElementHours(day?.schedule, 'from');
            if (firstElementHour) {
              return {
                nexStatusDay: day,
                hour: firstElementHour,
                nexStatusComponent:
                  <span>{`Abre ${day?.id == currentDay ? 'hoy' : 'el ' + WEEK_DAYS[day?.key]} a las ${firstElementHour}`}</span>
              };
            }
          }
        }
      }
    }
  }, [currentDay, currentVal, getDayFirstElementHours, getNextElementHour]);

  const getNextCloseStatus = useCallback(() => {
    if (currentVal[0].fullDay) {
      const closeHour = '23:59';
      return {
        nexStatusDay: currentVal[0],
        hour: closeHour,
        nexStatusComponent:
          <span>{`Cierra hoy a las ${closeHour}`}</span>
      };
    }
    let closeHour = getNextElementHour(currentVal[0], 'to');
    if (closeHour) {
      return {
        nexStatusDay: currentVal[0],
        hour: closeHour,
        nexStatusComponent:
          <span>{`Cierra ${currentVal[0]?.id == currentDay ? 'hoy' : 'el ' + WEEK_DAYS[currentVal[0]?.key]} a las ${closeHour}`}</span>
      };
    } else {
      for (let i = 1; i < currentVal?.length; i++) {
        const day = currentVal[i];
        if (!day?.value) {
          const closeHour = '0:00';
          return {
            nexStatusDay: day,
            hour: closeHour,
            nexStatusComponent:
              <span>{`Cierra ${day?.id == currentDay ? 'hoy' : 'el ' + WEEK_DAYS[day?.key]} a las ${closeHour}`}</span>
          };
        } else {
          let { firstElementHour } = getDayFirstElementHours(day?.schedule, 'to');
          if (firstElementHour) {
            return {
              nexStatusDay: day,
              hour: firstElementHour,
              nexStatusComponent:
                <span>{`Cierra ${day?.id == currentDay ? 'hoy' : 'el ' + WEEK_DAYS[day?.key]} a las ${firstElementHour}`}</span>
            };
          }
        }
      }
    }
  }, [currentDay, currentVal, getDayFirstElementHours, getNextElementHour]);

  const nextStatus = useMemo(() => {
    if (status === STATUS.CLOSE)
      return getNextOpenStatus();
    if (status === STATUS.OPEN)
      return getNextCloseStatus();
  }, [getNextCloseStatus, getNextOpenStatus, status]);

  const getSchedule = useCallback(() => {
    return currentVal?.map((day, index) => {
      console.log(day);
      return <div key={index} className="flex mt-3">
        <div className="min-w-32 opacity-75">{WEEK_DAYS[day?.key]}</div>
        {day?.schedule?.length > 0 && <div>{day?.schedule?.map((el, index) => {
          return <Space className="flex items-center" key={index}>
            <div>{el?.from}</div>
            <div>-</div>
            <div>{el?.to}</div>
          </Space>;
        })}
        </div>}
        {day?.fullDay && <div className="text-primary">{'Abierto 24 horas'}</div>}
        {!day?.value && <div className="text-error ">{'Cerrado'}</div>}
      </div>;
    });

  }, [currentVal]);

  useEffect(() => {
    const currentDay = currentVal[0];
    if (!currentDay?.value) {
      setStatus(STATUS.CLOSE);
    } else {
      if (currentDay?.fullDay) {
        setStatus(STATUS.OPEN);
      } else {
        let stat = STATUS.CLOSE;
        for (let i = 0; i < currentDay?.schedule?.length; i++) {
          const from = strTime2minutes(currentDay?.schedule[i]?.from);
          const to = strTime2minutes(currentDay?.schedule[i]?.to);
          if (currentTimeInMinutes >= from && currentTimeInMinutes <= to) {
            stat = STATUS.OPEN;
            break;
          }
        }
        setStatus(stat);
      }
    }
  }, [currentHour, currentDay, value, currentDate, currentVal, currentTimeInMinutes]);

  return (
    <CollapseSt ghost expandIconPosition={'right'}>
      <Panel className="max-w-90" header={<Header status={status} nextStatus={nextStatus}/>} key="1">
        <div className="max-w-70">
          {
            getSchedule()
          }
        </div>
      </Panel>
    </CollapseSt>
  );

};

export default memo(TimeList);

Header.propTypes = {
  status: PropTypes.string,
  nextStatus: PropTypes.any
};

TimeList.propTypes = {
  value: PropTypes.array.isRequired
};
TimeList.defaultProps = {
  value: [{
    key: 'MON',
    id: '1',
    value: false,
    schedule: [],
    fullDay: false
  }, {
    key: 'TUES',
    id: '2',
    value: false,
    schedule: [],
    fullDay: false
  }, {
    key: 'WED',
    id: '3',
    value: false,
    schedule: [],
    fullDay: false
  }, {
    key: 'THURS',
    id: '4',
    value: false,
    schedule: [],
    fullDay: false
  }, {
    key: 'FRI',
    id: '5',
    value: false,
    schedule: [],
    fullDay: false
  }, {
    key: 'SAT',
    id: '6',
    value: false,
    schedule: [],
    fullDay: false
  }, {
    key: 'SUN',
    id: '7',
    value: false,
    schedule: [],
    fullDay: false
  }]
};
